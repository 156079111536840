var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"manifest-list"}},[_c('v-card-title',[_vm._v("Manifests")]),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary","to":{ name: 'manifest-create' }}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',[_vm._v("Create Manifest")])],1)],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('CountrySelect',{staticClass:"mr-4",attrs:{"outlined":true,"inverted":false,"hide-details":true,"dense":true,"filter":{filter:{CanManifest: true}}},model:{value:(_vm.filter.CountryId),callback:function ($$v) {_vm.$set(_vm.filter, "CountryId", $$v)},expression:"filter.CountryId"}}),_c('v-select',{staticClass:"mr-4",attrs:{"items":_vm.filterItems.Closed,"single-line":"","outlined":"","dense":"","hide-details":"","clearable":"","placeholder":"Filter Status"},model:{value:(_vm.filter.Closed),callback:function ($$v) {_vm.$set(_vm.filter, "Closed", $$v)},expression:"filter.Closed"}})],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.CountryId_Name",fn:function(ref){
var item = ref.item;
return [(item.Closed)?_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'manifest-view', params: { id: item.Id } }}},[_vm._v(" "+_vm._s(item.CountryId_Name)+" ")]):_vm._e(),(!item.Closed)?_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'manifest-edit', params: { id: item.Id } }}},[_vm._v(" "+_vm._s(item.CountryId_Name)+" ")]):_vm._e()]}},{key:"item.ManifestDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Display.DisplayDate(item.ManifestDate))+" ")]}},{key:"item.Closed",fn:function(ref){
var item = ref.item;
return [(item.Closed)?_c('v-chip',{attrs:{"color":"error","text-color":"white","small":""}},[_vm._v("Closed")]):_vm._e(),(!item.Closed)?_c('v-chip',{attrs:{"color":"primary","text-color":"white","small":""}},[_vm._v("Open")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('ActionsDropdown',{attrs:{"item":item,"actionOptions":_vm.actionOptions}})],1)]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }